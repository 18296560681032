import paths from "./Routes/paths"

const Menu = [
  {
    heading: "Menu",
  },
  {
    name: "Meus Dados",
    icon: "icon-user",
    path: paths.usuarioMeusDados,
    // translate: 'sidebar.nav.element.ELEMENTS',
  },

  {
    name: "Relatórios",
    icon: "fas fa-file-excel",
    submenu: [
      {
        name: "Interno",
        path: "/relatorios/relatorioInterno",

      },
      {
        name: "Fechamento Cliente",
        path: "/relatorios/relatorioCliente",

      },
      {
        name: "Artes",
        path: "/relatorios/relatorioArte",

      },
    ],
  },

  {
    name: "Aviso",
    icon: "icon-speech",
    path: paths.aviso,
  },
  {
    name: "Banner do Login",
    icon: "fas fa-flag",
    path: paths.bannerLogin,
  },
  {
    name: "Banco Imagem",
    icon: "icon-picture",
    submenu: [
      {
        name: "Categoria Imagem",
        path: paths.bancoImagemCategoria,
        //translate: 'sidebar.nav.element.BUTTON'
      },
      {
        name: "Imagem",
        path: paths.bancoImagem+"/",
        //translate: 'sidebar.nav.element.BUTTON'
      },
    ],
  },
  {
    name: "Cadastro Produto",
    icon: "icon-bag",
    // translate: 'sidebar.nav.element.ELEMENTS',
    submenu: [
      {
        name: "Atividade Produto",
        path: paths.produtoatividade,
        //translate: 'sidebar.nav.element.BUTTON'
      },
      {
        name: "Categoria Produto",
        path: paths.produtoCategoria,
        //translate: 'sidebar.nav.element.BUTTON'
      },
      {
        name: "Preço Produto",
        path: "/search-preco",
        //translate: 'sidebar.nav.element.BUTTON'
      },
      {
        name: "Produto",
        path: paths.produto+"/",
        //translate: 'sidebar.nav.element.BUTTON'
      },
      {
        name: "Unidade",
        path: paths.unidade,
        //translate: 'sidebar.nav.element.BUTTON'
      },
    ],
  },
  {
    name: "Cadastro de Usuários",
    icon: "icon-people",
    submenu: [
      {
        name: "Tipo Usuário",
        path: paths.usuarioTipo,
        //translate: 'sidebar.nav.element.BUTTON'
      },
      {
        name: "Usuário",
        path: paths.usuario+"/",
        //translate: 'sidebar.nav.element.BUTTON'
      },
    ],
  },
  {
    name: "Canal",
    icon: "fas fa-chart-pie",
    path: "/canal",
  },
  {
    name: "Etapa Label",
    icon: "icon-tag",
    path: "/etapalabel",
  },
  {
    name: "Fluxo",
    icon: "fas fa-project-diagram",
    path: "/fluxo",
  },
  {
    name: "Forma Pagamento",
    icon: "icon-credit-card",
    path: paths.formaPagamento,
  },
  /* {
    name: "Fornecedor",
    icon: "icon-printer",
    path: paths.fornecedor,
  }, */
  {
    name: "Rede",
    icon: "fa fa-sitemap",
    path: paths.rede,
  },
  {
    name: "Setor",
    icon: "fa fa-tag",
    path: paths.setor,
  },
  {
    name: "Loja",
    icon: "fas fa-store",
    path: paths.loja,
  },
  {
    name: "Layout Arte",
    icon: "fa fa-file-image-o",
    submenu: [
      {
        name: "Materiais",
        path: paths.layoutArteMateriais,
        //translate: 'sidebar.nav.element.BUTTON'
      },
      // {
      //   name: "Artes Fácil",
      //   path: paths.layoutArteArtesFacil,
      //   //translate: 'sidebar.nav.element.BUTTON'
      // },
      // {
      //   name: "Ação",
      //   path: paths.layoutArteAcao,
      // },
    ],
  },
  // {
  //   name: "Campanha",
  //   icon: "fa fa-flag-checkered",
  //   path: paths.campanha,
  // },
  {
    name: "Material Comunicação",
    icon: "icon-book-open",
    // translate: 'sidebar.nav.element.ELEMENTS',
    submenu: [
      {
        name: "Tipo Material",
        path: paths.materialcomunicacaoTipo,
        //translate: 'sidebar.nav.element.BUTTON'
      },
      {
        name: "Material Comunicação",
        path: paths.materialcomunicacao,
        //translate: 'sidebar.nav.element.BUTTON'
      },
    ],
  },
  {
    name: "Motivo Ação",
    icon: "far fa-lightbulb", 
    path: paths.motivoAcao,
  },
  {
    name: "Ação Promocional",
    icon: "fa fa-check-double",
    submenu: [
      {
        name: "Categoria Ação",
        path: paths.acaoPromocionalCategoria,
      },
      {
        name: "Ação Promocional",
        path: paths.acaoPromocional,
      },
    ],
  },
  // {
  //   name: "Pendências",
  //   icon: "fa fa-warning",
  //   path: "/search-pendencia",
  // },
  {
    name: "Perguntas Frequentes",
    icon: "icon-question",
    path: paths.perguntafrequente,
  },
  {
    name: "Regional",
    icon: "icon-map",
    submenu:[
      {
        name: "Cadastro",
        path: "/regional",
      },
      {
        name: "Verba",
        path: "/regional-verba",
      }
    ]
  },
  {
    name: "Tipo de Chamado",
    icon: "fas fa-bullhorn",
    path: paths.chamadotipo,
  },
  {
    name: "Tipo de Pedido",
    icon: "fa fa-list-alt",
    path: paths.pedidotipo,
  },
  {
    name: "Valores Solicitação",
    icon: "fa fa-money-check-alt",
    submenu: [
      {
        name: "Valor Diagramação",
        path: "/valordiagramacao",
      },
      {
        name: "Valor Frete",
        path: "/valorfrete",
      },
      {
        name: "Valor Produção",
        path: "/valorproducao",
      }
    ]
  },
  {
    name: "Solicitação",
    icon: "fa fa-files-o",
    submenu: [
      {
        name: "Consultar Solicitação",
        path: "/solicitacao",
      },
      {
        name: "Alterar Pendência",
        path: "/alterar-pendencia",
      },
      {
        name: "Alterar Valores",
        path: "/solicitacao/valores",
      },
      {
        name: "Voltar Etapa",
        path: paths.solicitacaoVoltarEtapa,
      },
      // {
      //   name: "Arquivos Entrega",
      //   path: "/search-arquivo-entrega-loja-loja",
      // },
      // {
      //   name: "Trocar Etapa",
      //   path: "/trocar-etapa-solicitacao",
      // },
    ],
  },
];

export default Menu;
