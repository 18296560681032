//export const endpoint = "http://localhost:56039/";
//export const prodEndpoint = "http://localhost:56039/";
//export const endpoint = `https://apiadminhom.plataformatabloidesmarfrig.com.br/`;
export const endpoint = `https://apiadmin.plataformatabloidesmarfrig.com.br/`;
export const prodEndpoint = `https://apiadmin.plataformatabloidesmarfrig.com.br/`;

export const pagination = [20, 50, 100];

export const listaPageSize = [
  { value: 20, label: "20" },
  { value: 50, label: "50" },
  { value: 100, label: "100" },
];

export const listaSituacao = [
  { value: "", label: "TODOS" },
  { value: "A", label: "ATIVO" },
  { value: "I", label: "INATIVO" },
];
